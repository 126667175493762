<template>
  <div class=" ">
    <multiselect
      :id="'ms' + companyType"
      label="source"
      track-by="source"
      :placeholder="'Open to load and type to search ' + companyType"
      open-direction="bottom"
      :options="divisions.availableRecords"
      :value="selectedDivisions"
      @input="setSelectedDivisions"
      @open="load('')"
      :multiple="selectMultiple"
      :searchable="true"
      :loading="isSearching"
      :internal-search="true"
      :clear-on-select="false"
      :close-on-select="true"
      :limit="1"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="false"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          {{ customLabel(props) }}
        </div>
      </template>

      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="selectedDivisions.length"
          @mousedown.prevent.stop="clearSelected(props.search)"
        ></div>
      </template>

      <span slot="noResult"
        >No {{ companyType }} found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const BillTosRepository = RepositoryFactory.get("billTosRepository");

export default {
  props: ["userId", "customerId", "invalid", "selectMultiple"],
  components: {
    Multiselect
  },
  data() {
    return {
      companyType: "Divisions",
      isSearching: false,
      isDirty: true
    };
  },

  methods: {
    ...mapActions("companyMultiSelects", [
      "setDivisions",
      "setSelectedDivisions"
    ]),
		
    limitText(count) {
      return `and ${count} other ${this.companyType}`;
    },

    customLabel(props) {
      return `${props.option.source}`;
    },

    async load(query) {
      if (!this.isDirty) {
        return;
      }

      this.isSearching = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        customerId: this.customerId,
        userId: this.userId,
        searchTerm: query,
        skip: 0,
        take: 20
      };

      BillTosRepository.getDivisions(requestData)
        .then(response => {
          this.setDivisions({
            availableRecords: response.data.results,
            totalRecords: response.data.totalRecords
          });

          this.isDirty = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },

    clearSelected() {
      this.setSelectedDivisions([]);
    }
  },
  computed: {
    ...mapState("companyMultiSelects", ["divisions", "selectedDivisions"])
  }
};
</script>
